import * as React from 'react';
import { GadgetConfig } from './types';

export interface IContextValues {
  readonly uuid: string;
  readonly locale: string;
  readonly title: string;
  readonly editable: boolean;
  readonly isEditing: boolean;
  readonly config: GadgetConfig;
}

export interface IContextActions {
  setIsEditable: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  onChangeConfig: (config: GadgetConfig) => void;
  onSave?: (config: GadgetConfig) => boolean;
  onCancel: () => void;
  onDelete?: () => void;
}

export const ValuesContext = React.createContext<IContextValues>({
  uuid: '',
  locale: '',
  title: '',
  editable: false,
  isEditing: false,
  config: {},
});

export const ActionsContext = React.createContext<IContextActions>({
  setIsEditable: () => undefined,
  setIsEditing: () => undefined,
  onChangeConfig: () => undefined,
  onSave: () => false,
  onCancel: () => undefined,
  onDelete: () => undefined,
});

export interface Props {
  /** @property 가젯 고유 id */
  uuid: string;
  /** @property 지역화 언어 */
  locale: string;
  /** @property 가젯 제목 */
  title: string;
  /** @property 가젯 편집상태 (대시보드 편집) */
  editable?: boolean;
  /** @property 가젯 설정 */
  config?: GadgetConfig;
  /**
   * @function 가젯 설정 저장 버튼 클릭 이벤트 핸들러
   * @return 저장 성공여부를 알려주는 boolean값
   */
  onSave?: (config: GadgetConfig) => boolean;
  /** @function 가젯 설정 취소 버튼 클릭 이벤트 핸들러 */
  onCancel?: () => void;
  /** @function 가젯 삭제버튼 클릭 액션 */
  onDelete?: () => void;
}

export function Provider({
  uuid,
  locale: nationCode,
  title,
  editable: isEditable = false,
  config: initialConfig = { highlight: false, refresh: '-1' },
  children,
  onSave,
  onCancel,
  onDelete,
}: React.PropsWithChildren<Props>) {
  const [gadgetID] = React.useState(uuid);
  const [gadgetTitle, setGadgetTitle] = React.useState(title);
  const [editable, setIsEditable] = React.useState(isEditable);
  const [isEditing, setIsEditing] = React.useState(false);
  const [defaultConfig, setDefaultConfig] = React.useState(initialConfig);
  const [config, setConfig] = React.useState(defaultConfig);
  const [locale, setLocale] = React.useState(nationCode);

  const onChangeConfig = (changedConfig: object) => {
    setConfig((prevConfig) => ({ ...prevConfig, ...changedConfig }));
  };

  const onSaveConfig = (config: GadgetConfig) => {
    setDefaultConfig(config);
    return (onSave && onSave(config)) ?? false;
  };

  const onCancelAction = () => {
    onCancel && onCancel();
    setConfig({ ...defaultConfig });
  };

  const ctxValues = {
    uuid: gadgetID,
    locale,
    title: gadgetTitle,
    editable,
    isEditing,
    config,
  };

  const ctxActions = {
    setIsEditable,
    setIsEditing,
    onChangeConfig,
    onSave: onSaveConfig,
    onCancel: onCancelAction,
    onDelete,
  };

  React.useEffect(() => {
    setGadgetTitle(title);
  }, [title]);

  React.useEffect(() => {
    setLocale(nationCode);
  }, [nationCode]);

  React.useEffect(() => {
    setIsEditable(isEditable);
  }, [isEditable]);

  React.useEffect(() => {
    setConfig(initialConfig);
  }, [initialConfig]);

  return (
    <ActionsContext value={ctxActions}>
      <ValuesContext value={ctxValues}>{children}</ValuesContext>
    </ActionsContext>
  );
}

export const useValuesContext = () => React.useContext(ValuesContext);
export const useActionsContext = () => React.useContext(ActionsContext);

export const useContext = () => {
  const values = useValuesContext();
  const actions = useActionsContext();

  return {
    ...values,
    ...actions,
  };
};

export function useGenericValuesContext<T extends GadgetConfig>() {
  const values = useValuesContext();

  return {
    ...values,
    config: values.config as T,
  };
}

export function useGenericActionsContext<T extends GadgetConfig>() {
  const actions = useActionsContext();

  return {
    ...actions,
    onChangeConfig: actions.onChangeConfig as (config: T) => void,
  };
}

export function useGenericContext<T extends GadgetConfig>() {
  const values = useValuesContext();
  const actions = useActionsContext();

  return {
    ...values,
    ...actions,
    config: values.config as T,
    onChangeConfig: actions.onChangeConfig as (config: T) => void,
  };
}
