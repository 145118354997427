import { useEffect, useState } from 'react';
import { useDashboardContext } from '../../../context';
import { readGadgets } from '../../../apis';
import { GadgetApiParams } from '../../../types';
import ChatGadgetContainer from './chat-gadget-container';
import * as DnDList from '../../../../../shared/ui/dnd/list';
import { Edge } from '../../../../../shared/lib/dnd/types';

interface Props {
  width: number;
}

export function ChatGadgetBoard({ width }: Props) {
  const [gadgetWidhStyle, setGadgetWidthStyle] = useState<string>('w-[280px]');
  const context = useDashboardContext();
  const { gadgets, currentDashboard, isEditMode } = context.state;
  const { setOriginGadgets, setGadgets } = context.actions;

  const handleOnDragOver = ({
    sourceData,
    targetData,
    edge,
  }: DnDList.DropParams) => {
    const sourceGadget = gadgets.find(
      (gadget) => gadget.uuid === sourceData['uuid'],
    );
    const targetGadget = gadgets.find(
      (gadget) => gadget.uuid === targetData['uuid'],
    );
    if (!sourceGadget || !targetGadget) return;
    arrangeGadgets(sourceGadget, targetGadget, edge);

    // 미리보기 리스트 상태 설정
    gadgets.sort((a, b) => a.sequence - b.sequence);
    setGadgets([...gadgets]);
  };

  const arrangeGadgets = (
    sourceGadget: GadgetApiParams,
    targetGadget: GadgetApiParams,
    edge: Edge | null,
  ) => {
    // rearrange algorithm
    if (sourceGadget.sequence < targetGadget.sequence) {
      const originSourceGadgetSequence = sourceGadget.sequence;
      if (edge === 'left') {
        sourceGadget.sequence = targetGadget.sequence - 1;
        // targetGadget - 1부터 originSourceGadget + 1까지 seq - 1
        for (
          let i = targetGadget.sequence - 1;
          i >= originSourceGadgetSequence + 1;
          i--
        ) {
          gadgets[i]!.sequence -= 1;
        }
      } else if (edge === 'right') {
        sourceGadget.sequence = targetGadget.sequence;

        // targetGadget부터 originSourceGadget + 1까지 seq - 1
        for (
          let i = targetGadget.sequence;
          i >= originSourceGadgetSequence + 1;
          i--
        ) {
          gadgets[i]!.sequence -= 1;
        }
      }
    } else if (sourceGadget.sequence > targetGadget.sequence) {
      const originSourceGadgetSequence = sourceGadget.sequence;
      if (edge === 'left') {
        sourceGadget.sequence = targetGadget.sequence;
        // targetGadget부터 originSourceGadget - 1까지 seq + 1
        for (
          let i = targetGadget.sequence;
          i <= originSourceGadgetSequence - 1;
          i++
        ) {
          gadgets[i]!.sequence += 1;
        }
      } else if (edge === 'right') {
        const originSourceGadgetSequence = sourceGadget.sequence;
        sourceGadget.sequence = targetGadget.sequence + 1;
        // targetGadget + 1부터 sourceGadget - 1까지 seq + 1
        for (
          let i = targetGadget.sequence + 1;
          i <= originSourceGadgetSequence - 1;
          i++
        ) {
          gadgets[i]!.sequence += 1;
        }
      }
    }
  };

  useEffect(() => {
    const changeGadgetWidth = () => {
      if (width >= 355 && width < 660) {
        setGadgetWidthStyle('w-full');
      } else if (width >= 660 && width < 960) {
        setGadgetWidthStyle('w-[calc(50%-24px)]');
      } else if (width >= 960) {
        setGadgetWidthStyle('w-[calc(33%-24px)]');
      }
    };

    changeGadgetWidth();
  }, [width]);

  useEffect(() => {
    const getGadgetData = () => {
      if (!currentDashboard) return;
      if (currentDashboard.dashboardId === -1) return;
      if (isEditMode) return;
      // readGadgets messenger 용으로 하나 받아와야함.
      readGadgets(currentDashboard.dashboardId)
        .then((gadgetsdData) => {
          setGadgets(gadgetsdData.gadgetList);
          setOriginGadgets(gadgetsdData.gadgetList);
        })
        .catch((e) => console.log(e));
    };

    getGadgetData();
  }, [setOriginGadgets, setGadgets]);

  if (!currentDashboard) return;

  return (
    <>
      {isEditMode ? (
        <DnDList.Provider idKey="uuid" name="chat_gadget_board">
          <DnDList.Container
            list={gadgets}
            setList={setGadgets}
            onDrop={() => {}}
            onDropTargetChange={handleOnDragOver}
          >
            <div className="flex flex-wrap flex-row w-full gap-6 !z-0">
              {gadgets.map((gadget: GadgetApiParams, index: number) => (
                <DnDList.Item
                  key={gadget.uuid}
                  item={gadget}
                  className={`${gadgetWidhStyle}`}
                  allowedEdges={['left', 'right']}
                  useDropIndicator={false}
                  useDragOverlay={true}
                >
                  <ChatGadgetContainer
                    key={`gadget-${gadget.uuid}`}
                    gadget={gadget}
                    isEditMode={isEditMode}
                    seq={index}
                    dashboardId={currentDashboard.dashboardId}
                  ></ChatGadgetContainer>
                </DnDList.Item>
              ))}
            </div>
          </DnDList.Container>
        </DnDList.Provider>
      ) : (
        <>
          <div className="flex flex-wrap flex-row w-full gap-6">
            {gadgets.map((gadget: GadgetApiParams, index: number) => (
              <div key={gadget.uuid} className={`${gadgetWidhStyle}`}>
                <ChatGadgetContainer
                  key={gadget.uuid}
                  gadget={gadget}
                  isEditMode={isEditMode}
                  seq={index}
                  dashboardId={currentDashboard.dashboardId}
                ></ChatGadgetContainer>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}
