import Header from './header';
import { Root, type Props as RootProps } from './root';
import Content from './content';
import { ChatHeader } from './chat/header/chat-header';
import { ChatDashboardContent } from './chat/content/gadget-content';
import { ManageDashboardContainer } from './manage';

export * from '../types';

export interface Props extends RootProps {
  width?: number;
  isEditMode?: boolean;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Dashboard({
  width = 0,
  isEditMode = false,
  setEditMode,
  dashboardType = 'EMPLOYEE',
  ...props
}: Props) {
  return (
    <Root {...props} dashboardType={dashboardType}>
      {dashboardType === 'EMPLOYEE' && <EmpPortalDashboard {...props} />}
      {dashboardType === 'MESSENGER' && (
        <MessengerDashboard width={width} {...props} />
      )}
      {dashboardType === 'BUSINESS' && (
        <ManagePortalDashboard
          isEditMode={isEditMode}
          setEditMode={setEditMode}
          {...props}
        />
      )}
    </Root>
  );
}

function EmpPortalDashboard(props: Omit<RootProps, 'dashboardType'>) {
  return (
    <Root {...props} dashboardType="EMPLOYEE">
      <Header />
      <Content />
    </Root>
  );
}

function MessengerDashboard({
  width = 0,
  ...props
}: Omit<Props, 'dashboardType' | 'isEditMode' | 'setEditMode'>) {
  return (
    <Root {...props} dashboardType="MESSENGER">
      <ChatHeader />
      <ChatDashboardContent width={width} />
    </Root>
  );
}

function ManagePortalDashboard({
  isEditMode = false,
  setEditMode,
  ...props
}: Omit<Props, 'dashboardType' | 'width'>) {
  return (
    <Root {...props} dashboardType="BUSINESS">
      <ManageDashboardContainer
        isEditMode={isEditMode}
        setEditMode={setEditMode}
      />
    </Root>
  );
}
