'use client';

export function setCookie(key: string, value: string, options: string = '') {
  document.cookie = `${key}=${value} ${options}`;
}

export function getCookie(name: string): string | undefined {
  const matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' + name.replace(/([.$?*|{}()[]\\\/\+^])/g, '\\$1') + '=([^;]*)',
    ),
  );

  return matches && matches[1] 
    ? decodeURIComponent(matches[1]) 
    : undefined;
}
