'use client';

import { useEffect } from 'react';

import gadgetList from '@dop-gadgets/loader';
import thumbnailList from '@dop-gadgets/loader/thumbnails';
import { preLoadDOResources } from '@daouoffice/next/components/DaouOffice';
import {
  default as Dashboard,
  GadgetComponentType,
} from '@dop-ui/react/widgets/dashboard';
import { PopupAnnouncementProvider } from '@/components/Providers/PopupAnnouncementProvider';

const NEXT_PUBLIC_DO_PREFIX = process.env['NEXT_PUBLIC_DO_PREFIX'];
const DO_RESOURCE_PATH = `${NEXT_PUBLIC_DO_PREFIX}resources`;
const STYLES = [
  { href: `${DO_RESOURCE_PATH}/css/go_app_style.css` },
  { href: `${DO_RESOURCE_PATH}/css/go_renew.css` },
  { href: `${DO_RESOURCE_PATH}/css/go_app_style2.css` },
  { href: `${DO_RESOURCE_PATH}/css/go_app_style3.css` },
  { href: `${DO_RESOURCE_PATH}/css/go_style.css` },
  { href: `${DO_RESOURCE_PATH}/css/go_home_dashboard.css` },
  { href: `${DO_RESOURCE_PATH}/css/master_style.css` },
];

export default function AppContainer() {
  useEffect(() => {
    preLoadDOResources(STYLES);
  });

  const typedGadgetList = gadgetList as Record<string, GadgetComponentType>;
  const typedThumbList = thumbnailList as Record<string, string>;
  const gadgetLoader = (pkgName: string) => typedGadgetList[pkgName];
  const thumbnailLoader = (pkgName: string) => typedThumbList[pkgName];

  return (
    <PopupAnnouncementProvider>
      <Dashboard
        dashboardType="EMPLOYEE"
        gadgetLoader={gadgetLoader}
        thumbnailLoader={thumbnailLoader}
      />
    </PopupAnnouncementProvider>
  );
}
