import styles from './styles.module.css';
import { clsx } from 'clsx';

export function GadgetContentLoader() {
  return (
    <div className={styles.GadgetContentLoader}>
      <div className={styles.title}></div>

      <div className={styles.body}>
        <div className={clsx(styles.paragraph, styles.pNormal)}></div>
        <div className={clsx(styles.paragraph, styles.pEnd)}></div>

        <div className={styles.space} />

        <div className={clsx(styles.paragraph, styles.pNormal)}></div>
        <div className={clsx(styles.paragraph, styles.pNormal)}></div>
        <div className={clsx(styles.paragraph, styles.pEnd)}></div>

        <div className={styles.space} />

        <div className={clsx(styles.paragraph, styles.pNormal)}></div>
        <div className={clsx(styles.paragraph, styles.pEnd)}></div>
      </div>
    </div>
  );
}

export default GadgetContentLoader;
