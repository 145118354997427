import { useState } from 'react';
import { generateUUID } from '../../apis';
import { GadgetApiParams } from '../../types';
import { useDashboardActionsContext } from '../../context';
import { getGadgetDefaultData } from '../header/control/gadget-spec-item';
import { useTranslation } from '../../../../shared/lib/i18n/client';
import { clsx } from 'clsx';

export interface Props {
  dashboardId: number;
  column: number;
  seq: number;
  isLastArea?: boolean;
  isDragOverParam?: boolean;
  setIsDragOverParam?: (isDragOver: boolean) => void;
}

export function GadgetDropArea({
  column,
  seq,
  isDragOverParam,
  setIsDragOverParam = () => {},
}: Props) {
  const { t } = useTranslation('component');
  const [isDragOver, setIsDragOver] = useState(isDragOverParam);

  const context = useDashboardActionsContext();
  const { reorderGadgets } = context;

  const handleDropMoveGadget = (gadget: GadgetApiParams) => {
    const region = column - 1;
    const changedGadget = {
      ...gadget,
      region,
      sequence: seq,
    };
    reorderGadgets(changedGadget, region, seq);
  };

  const handleDropAddGadget = (gadget: GadgetApiParams) => {
    const region = column - 1;
    const newGadget = {
      ...getGadgetDefaultData(),
      ...gadget,
      region,
      sequence: seq,
      uuid: generateUUID(),
    };
    reorderGadgets(newGadget, region, seq);
  };

  const handleDrop = (e: React.DragEvent) => {
    if (!e.nativeEvent.dataTransfer) return;

    const gadget = JSON.parse(
      e.nativeEvent.dataTransfer.getData('text/plain'),
    ) as GadgetApiParams;

    gadget.uuid ? handleDropMoveGadget(gadget) : handleDropAddGadget(gadget);

    setIsDragOver(false);
    setIsDragOverParam(false);
  };

  const handleDragOver = (e: React.DragEvent) => {
    if (!e.nativeEvent.dataTransfer) return;
    e.preventDefault();
    e.nativeEvent.dataTransfer.dropEffect = 'move';
  };

  const handleDragLeave = (e: React.DragEvent) => {
    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      setIsDragOver(false);
    }
  };

  const handleDragEnter = () => {
    setIsDragOver(true);
  };

  return (
    <div
      className={clsx(
        'rounded-2xl border-2 !border-dashed !border-[#D9D9D9] flex items-center justify-center h-[320px]',
        {
          'go-gadget-placeholder !h-[320px]': isDragOver,
          'dashboard-box-guide': !isDragOver,
        },
      )}
      onDrop={(e) => void handleDrop(e)}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDragEnter={handleDragEnter}
      style={{
        border: '1px dashed rgb(170, 170, 170)',
        minHeight: '100px',
      }}
    >
      <p className="text-[#C5C5C5] text-[16px]">
        {t('dashboard.gadget.select')}
      </p>
    </div>
  );
}
