'use client';

import { getGoAgent } from './ClientUtil';

const GoAgent = () => {
  const goAgent = getGoAgent();
  return <meta name="goAgent" content={goAgent}></meta>;
};

export default GoAgent;
