import { TextProps } from './type';
import { Languages } from '../../utils/locale';

export const GadgetTexts: Languages<TextProps> = {
  ko: { gadgetTitle: '' },
  en: { gadgetTitle: '' },
  ja: { gadgetTitle: '' },
  zh_CN: { gadgetTitle: '' },
  zh_TW: { gadgetTitle: '' },
  vi: { gadgetTitle: '' },
};
