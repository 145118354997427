import * as React from 'react';

import {
  DashboardProvider,
  type Props as DashboardProviderProps,
} from './provider';

export interface Props extends DashboardProviderProps {}

export function Root({ children, ...props }: React.PropsWithChildren<Props>) {
  return (
    <div className="dop-dashboard h-full w-full">
      <DashboardProvider {...props}>{children}</DashboardProvider>
    </div>
  );
}

export default Root;
