import { GO_ANDROID, GO_IPHONE, BROWSER } from './Constants';

const DO_PREFIX = process.env['NEXT_PUBLIC_DO_PREFIX'];
const DO_ADMIN_PREFIX = process.env['NEXT_PUBLIC_DO_ADMIN_PREFIX'];

export interface Style {
  href: string;
  media?: string;
}

export interface Script {
  src: string;
  deps?: Array<Script>;
}

export const getGoAgent = (): string => {
  if (typeof window === 'undefined') return '';
  const { userAgent } = window.navigator;
  if (/android/i.test(userAgent)) {
    return GO_ANDROID;
  } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    return GO_IPHONE;
  } else {
    return BROWSER;
  }
};

export const isMobile = () => {
  if (typeof window === 'undefined') return false;
  const { userAgent } = window.navigator;
  console.log(userAgent);
  return /android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent);
};

export const loadScript = (script: Script, prefix: string = '') => {
  const scriptEl = document.createElement('script');
  scriptEl.src = `${prefix}${script.src}`;
  scriptEl.onload = () => {
    script.deps?.forEach((dep) => {
      loadScript(dep, prefix);
    });
  };
  scriptEl.setAttribute('service-type', 'DO');
  scriptEl.setAttribute('type', 'module');
  const headEl = document.getElementsByTagName('head')[0];
  headEl?.appendChild(scriptEl);
};

const loadStyle = (style: Style) => {
  const linkEl = document.createElement('link');
  linkEl.rel = 'stylesheet';
  linkEl.type = 'text/css';
  linkEl.href = `${style.href}`;
  linkEl.media = style.media || 'all';
  linkEl.setAttribute('service-type', 'DO');
  const headEl = document.getElementsByTagName('head')[0];
  headEl?.appendChild(linkEl);
};

export const preLoadDOResources = (styles: Array<Style>) => {
  styles.forEach((style) => {
    loadStyle(style);
  });
};

export const platformLogin = async () => {
  if (process.env.NODE_ENV === 'development') {
    const referer = process.env.DOP_GATEWAY || '';
    return await fetch(`/api/portal/public/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Referer-Info': referer,
      },
      body: JSON.stringify({
        loginId: process.env.NEXT_PUBLIC_PLATFORM_DEV_LOGIN_ID,
        password: process.env.NEXT_PUBLIC_PLATFORM_DEV_PW,
        companyId: process.env.NEXT_PUBLIC_PLATFORM_DEV_COMPANY_ID,
      }),
    }).catch((e) => {
      console.log(e);
      return Promise.resolve();
    });
  } else {
    return Promise.resolve();
  }
};

export const login = async () => {
  console.log('gw login');
  if (process.env.NODE_ENV === 'development') {
    await fetch(`${DO_PREFIX}api/user/session`)
      .then((resp) => {
        return resp.json();
      })
      .then((session: { code: string }) => {
        if (session.code !== '200') {
          return fetch(`${DO_PREFIX}api/login`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: process.env.NEXT_PUBLIC_DO_DEV_USER_NAME,
              password: process.env.NEXT_PUBLIC_DO_DEV_PW,
            }),
          });
        }
      })
      .catch((e) => console.log(e));
  } else {
    return Promise.resolve();
  }
};

export const adminLogin = async () => {
  if (process.env.NODE_ENV === 'development') {
    return fetch(`${DO_ADMIN_PREFIX}ad/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: 'mailadm',
        password: '1qaz2wsx#',
      }),
    });
  } else {
    return Promise.resolve();
  }
};
