export type GadgetStatus = 'none' | 'inDashboardEdit';

export interface GadgetConfig {
  highlight?: boolean;
  refresh?: string;
  [key: string]: unknown;
}

/**
 * 가젯 공통 속성 (대시보드에서 가젯으로 전달되는 속성)
 */
export interface GadgetProps<Config extends GadgetConfig> {
  /** @type {string} 가젯 인스턴스 아이디. 배치되는 가젯마다 별도로 부여됨 */
  uuid: string;
  /** @type {boolean} 편집 모드 여부 */
  editable: boolean;
  /** @type {() => void} 삭제 이벤트 핸들러 */
  onDelete: () => void;
  /** @type {(config: Config) => void} 저장 이벤트 핸들러 */
  onSave: (config: GadgetConfig) => void;
  /** @type {Config} 가젯 옵션 */
  config: Config;
  /** @property 지역화 언어 */
  locale?: string;
  /** @property 가젯이 들어갈 대시보드의 이름 */
  dashboardName?: string;
}

export const refreshRate = {
  none: '-1',
  oneMin: '1',
  fiveMin: '5',
  tenMin: '10',
  fifteenMin: '15',
  thirtyMin: '30',
  oneHour: '60',
  twoHour: '120',
} as const;

export type RefreshRate = (typeof refreshRate)[keyof typeof refreshRate];

/**
 * 게시판 데이터 타입
 */
export type BoardViewType = 'normal' | 'album' | 'thumbnail';

export interface BoardInfo {
  id: number;
  createdAt: string;
  boardId: number;
  boardName: string;
  boardMasterOwner: BoardMasterOwner;
  status: string;
  summary: string;
  recommend: boolean;
  recommendCount: number;
  commentsCount: number;
  repliesCount: number;
  hasAttach: boolean;
  authorizedUsers: unknown[];
  replyForMe: boolean;
  hiddenPost: boolean;
  hiddenTitle: boolean;
  readCount: number;
  stickable: boolean;
  readPost: boolean;
  publicWriter: boolean;
  repImage?: RepImage;
  newFlag: boolean;
  type?: string;
  writer: Writer;
  postWriterId?: number;
  actions?: Actions;
  title?: string;
  writerId?: number;
}

export interface BoardMasterOwner {
  id: number;
  ownerId: number;
  ownerInfo: string;
  ownerShip: string;
  userId?: number;
}

export interface RepImage {
  id?: number;
  createdAt?: string;
  updatedAt?: string;
  name?: string;
  path?: string;
  extension?: string;
  size?: number;
  clipboard?: boolean;
  mobilePreview?: boolean;
  download?: boolean;
}

export interface Writer {
  id: number;
  name: string;
  companyName: string;
  positionName: string;
  otherCompanyUser: boolean;
  photoPath: string;
}

export interface Actions {
  updatable: boolean;
  removable: boolean;
  writable: boolean;
  managable: boolean;
}
