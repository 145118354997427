import * as React from 'react';

import TempGadget from '../temp-gadgets';
import { GadgetConfig, GadgetProps } from '../gadget';

import type { GadgetApiParams } from '../../types';
import { useDashboardActionsContext } from '../../context';
import { DeleteDialogProps } from '../manage/header/control/manage-delete-dialog';
import { GadgetContentLoader } from './content-loader';

export interface Props {
  spec: GadgetApiParams;
  isEditMode: boolean;
  DeleteDialog?: React.ComponentType<DeleteDialogProps>;
  dashboardName?: string;
}

export default function Gadget({
  spec,
  isEditMode,
  DeleteDialog,
  dashboardName,
}: Props) {
  const context = useDashboardActionsContext();
  const { removeGadget, editGadget, getGadgetComponent } = context;
  const [open, setOpen] = React.useState(false);

  const handleDeleteGadgetClick = () => {
    DeleteDialog && setOpen(true);
    !DeleteDialog && removeGadget(spec.uuid);
  };
  const handleSaveGadgetClick = (options: GadgetConfig) => {
    const newGadget = {
      ...spec,
      option: JSON.stringify(options),
    };
    editGadget(newGadget);
  };

  const gadgetConfig = JSON.parse(spec.option || '{}') as GadgetConfig;

  const baseProps: GadgetProps<GadgetConfig> = {
    editable: isEditMode,
    onDelete: handleDeleteGadgetClick,
    onSave: handleSaveGadgetClick,
    config: gadgetConfig,
    uuid: spec.uuid,
    dashboardName,
  };

  const renderGadget = () => {
    const Comp = getGadgetComponent(spec.name);

    if (!Comp) {
      return <TempGadget gadget={spec} {...baseProps}></TempGadget>;
    }

    return <Comp {...baseProps} />;
  };

  return (
    <React.Suspense fallback={<GadgetContentLoader />}>
      {DeleteDialog && (
        <DeleteDialog
          onDelete={() => removeGadget(spec.uuid)}
          open={open}
          setOpen={setOpen}
          gadgetName={spec.displayName ?? ''}
        />
      )}
      {renderGadget()}
    </React.Suspense>
  );
}
