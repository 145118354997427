import * as React from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import {
  DashboardActionsContext,
  DashboardValuesContext,
  DashboardActionsContextType,
  DashboardValuesContextType,
} from '../../context';
import { readDashboards } from '../../apis';
import type {
  DashboardProps,
  GadgetApiParams,
  GadgetSpecProps,
  GadgetComponentType,
  DashboardType,
} from '../../types';

export interface Props {
  dashboardType: DashboardType;
  gadgetLoader: (pkgName: string) => GadgetComponentType | undefined;
  thumbnailLoader?: (pkgName: string) => string | undefined;
}

export const DashboardProvider = ({
  dashboardType,
  gadgetLoader,
  thumbnailLoader,
  children,
}: React.PropsWithChildren<Props>) => {
  const [isEditMode, setEditMode] = React.useState<boolean>(false);
  const [dashboards, setDashboards] = React.useState<Array<DashboardProps>>([]);
  const [gadgetSpecs, setGadgetSpecs] = React.useState<Array<GadgetSpecProps>>(
    [],
  );
  const [originGadgets, setOriginGadgets] = React.useState<
    Array<GadgetApiParams>
  >([]);
  const [gadgets, setGadgets] = React.useState<Array<GadgetApiParams>>([]);
  const [currentDashboard, setCurrentDashboard] =
    React.useState<DashboardProps | null>(null);
  const prevDashboardRef = React.useRef<DashboardProps | null>(null);

  const {
    status: dashboardStatus,
    data: dasboardsData,
    isFetched,
  } = useSuspenseQuery({
    queryKey: ['dop-dashboards'],
    queryFn: () => readDashboards(dashboardType),
  });

  React.useEffect(() => {
    if (isFetched && dashboardStatus === 'success') {
      const { dashboardList } = dasboardsData;
      const activatedDashboard = dashboardList.find((item) => item.activated);
      if (activatedDashboard) {
        setCurrentDashboard(activatedDashboard);
      }
      setDashboards(dashboardList);
    }
  }, [isFetched, dashboardStatus, dasboardsData]);

  const getCompanyDashboard = () => {
    return dashboards.find((item: DashboardProps) => !item.deletable);
  };

  const getGadgetsByColumnSeq = (columnSeq: number) => {
    return gadgets.filter((item: GadgetApiParams) => item.region === columnSeq);
  };

  const isPreventDrop = (currentGadget: GadgetApiParams) => {
    const draggedGadget = gadgets.find(
      (item: GadgetApiParams) => item.isDragging,
    );
    if (!draggedGadget) return false;
    return (
      draggedGadget.region === currentGadget.region &&
      draggedGadget.sequence === 0 &&
      currentGadget.sequence === 1
    );
  };

  const addDashboards = (
    newDashboard: DashboardProps | Array<DashboardProps>,
  ) => {
    if (dashboards.length > 4) return;
    const newDashboards = Array.isArray(newDashboard)
      ? newDashboard
      : [newDashboard];
    setDashboards([
      ...dashboards.map((item) => {
        return { ...item, activated: false };
      }),
      ...newDashboards,
    ]);
  };

  const removeDashboard = (id: number) => {
    let afterDashboards = dashboards.filter((item: DashboardProps) => {
      return item.dashboardId !== id;
    });
    afterDashboards = afterDashboards.map((item) => {
      return {
        ...item,
        activated: !item.deletable,
      };
    });
    setDashboards(afterDashboards);
  };

  const editDashboard = (dashboard: DashboardProps) => {
    const afterDashboards = dashboards.map((item: DashboardProps) => {
      if (item.dashboardId === dashboard.dashboardId) {
        return { ...dashboard, activated: true };
      } else {
        return item;
      }
    });
    setDashboards(afterDashboards);
  };

  const toggleDashboard = (id: number) => {
    const afterDashboards = dashboards.map((item) => {
      return { ...item, activated: item.dashboardId === id };
    });
    setDashboards(afterDashboards);
  };

  const addGadget = (newGadget: GadgetApiParams) => {
    const gadgetList = [...gadgets, { ...newGadget }];
    setGadgets(gadgetList);
  };

  const editGadget = (newGadget: GadgetApiParams) => {
    const newGadgets = gadgets.map((gadget: GadgetApiParams) => {
      if (gadget.uuid === newGadget.uuid) {
        return Object.assign({}, { ...gadget }, newGadget);
      } else {
        return gadget;
      }
    });
    setGadgets(newGadgets);
  };

  const removeGadget = (uuid: string) => {
    const afterGadgets = gadgets.filter((item) => {
      return item.uuid !== uuid;
    });
    setGadgets(afterGadgets);
  };

  const sortGadgets = (gadgets: Array<GadgetApiParams>) => {
    return gadgets.sort((a, b) => {
      if (a.region < b.region) return -1;
      if (a.region > b.region) return 1;
      if (a.sequence < b.sequence) return -1;
      if (a.sequence > b.sequence) return 1;
      return 0;
    });
  };

  const reorderGadgets = (
    targetGadget: GadgetApiParams,
    column: number,
    seq: number,
  ) => {
    sortGadgets(gadgets);

    const getGadgetColumn = (column: number) => {
      if (gadgetMap[column] === undefined) {
        gadgetMap[column] = [];
      }
      return gadgetMap[column] as Array<GadgetApiParams>;
    };

    const gadgetMap = [[]];
    gadgets.forEach((gadget: GadgetApiParams) => {
      if (gadget.uuid !== targetGadget.uuid) {
        const gadgetColumn = getGadgetColumn(gadget.region);
        gadgetColumn[gadget.sequence] = gadget;
      }
    });

    const gadgetColumn = getGadgetColumn(column);
    gadgetColumn.splice(seq, 0, targetGadget);

    const newGadgets: GadgetApiParams[] = [];
    gadgetMap.forEach((inner, region) => {
      let sequence = 0;
      inner.forEach((gadget) => {
        if (gadget) {
          newGadgets.push(Object.assign({}, gadget, { region, sequence }));
          sequence += 1;
        }
      });
    });

    setGadgets(newGadgets);
  };

  const setPrevDashboard = (dashboard: DashboardProps | null) => {
    if (dashboard === null) {
      prevDashboardRef.current = null;
    } else {
      prevDashboardRef.current = { ...dashboard };
    }
  };

  const values: DashboardValuesContextType = {
    dashboards,
    gadgetSpecs,
    originGadgets,
    gadgets,
    currentDashboard,
    get prevDashboard() {
      return prevDashboardRef.current;
    },
    isEditMode,
  };

  const actions: DashboardActionsContextType = {
    getGadgetComponent: gadgetLoader,
    getGadgetThumbnail: thumbnailLoader,
    setDashboards,
    addDashboards,
    removeDashboard,
    toggleDashboard,
    editDashboard,
    setGadgetSpecs,
    setOriginGadgets,
    setGadgets,
    addGadget,
    editGadget,
    removeGadget,
    getCompanyDashboard,
    setCurrentDashboard: (dashboard: DashboardProps | null) => {
      setCurrentDashboard(dashboard);
    },
    setPrevDashboard: (dashboard: DashboardProps | null) => {
      setPrevDashboard(dashboard);
    },
    getGadgetsByColumnSeq,
    isPreventDrop,
    reorderGadgets,
    setEditMode: (bool: boolean) => setEditMode(bool),
  };

  return (
    <DashboardActionsContext value={actions}>
      <DashboardValuesContext value={values}>{children}</DashboardValuesContext>
    </DashboardActionsContext>
  );
};
